import React from 'react';

const Player = Loader.loadBusinessComponent('Player');

@Decorator.businessProvider('device')
class PlayComponent extends React.Component {
  render() {
    let { fileData = {}, hasLiving, hasHistory, hasDownload=true, children, ...rest } = this.props;
    hasLiving = hasLiving === undefined ? !!BaseStore.menu.getInfoByName('realVideo') : hasLiving;
    hasHistory = hasHistory === undefined ? !!BaseStore.menu.getInfoByName('historyVideo') : hasHistory;
    hasDownload = hasDownload && !!BaseStore.menu.getInfoByName('videoDownload');
    return (
      <Player
        hasHistory={hasHistory}
        hasLiving={hasLiving}
        hasDownload={hasDownload}
        hasFrontEnd={hasHistory}
        ptzControl={fileData.deviceType * 1 === 100602}
        fileData={fileData}
        {...rest}
      >
        {children}
      </Player>
    );
  }
}
export default PlayComponent;